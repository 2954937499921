import React, { Component, createRef } from 'react';
import ReactECharts from 'echarts-for-react'
import { TbLayoutBottombar } from "react-icons/tb"
import { BsBoxArrowRight, BsBucket, BsArrowRightShort, BsCashCoin, BsPeople, BsCpu } from "react-icons/bs";

export const emptyState = {
  label: '',
  description: '',
  multiplier: '0',
  from: '1',
  to: '36',
  incoming: {},
  data: []
}

export const computeData = (data: any) => {
  const dataRange = []
  let from = parseInt(data.from)
  let to = parseInt(data.to)
  if (from < 1) {from = 1}
  if (from > 36) {from = 36}
  if (to < 1) {to = 1}
  if (to > 36) {to = 36}
  for (const e of Array.from(Array(36).keys())) {
    const el = e+1
    let totalMonth = 0
    for(const key of Object.keys(data.incoming)){
      if (data.incoming[key] !== undefined && data.incoming[key][e] !== undefined && data.incoming[key][e].money !== undefined) {
        totalMonth = totalMonth + data.incoming[key][e].money
      }
    }
    const convertedPerMonth = Number(data.multiplier ? data.multiplier : 0) * totalMonth
    if (el >= from && el <= to) {
      dataRange.push({
        month: el,
        money: convertedPerMonth
      })
    } else {
      dataRange.push({
        month: el,
        money: 0
      })
    }
  }
  return {...data, data: dataRange}
}

export const processGraph = (data: any) => {
  const toReturn = data.data.map((el: any) => { return el.money })
  return toReturn
}

type UserProcessorEditStates = {
  data: any
}

interface UserProcessorEditProps {
  updateNodeData: any
  showGraph: any
  node: any
  modifyable: any
}

export default class UserProcessorEdit extends Component <UserProcessorEditProps, UserProcessorEditStates> {

  inputRef: any = createRef();

  constructor (props: UserProcessorEditProps) {
    super(props)
    this.state = {
      data: {...emptyState, ...this.props.node.data}
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.node.id !== this.props.node.id) {
      this.setState({data: this.props.node.data})
    }
  }

  onChange = (event: any) => {
    if (!this.props.modifyable) return
    const data = this.state.data
    data[event.currentTarget.name] = event.currentTarget.value
    this.setState({ data } as UserProcessorEditStates)
  }

  onBlur = () => {
    this.setState({ data: computeData(this.state.data) }, () => this.props.updateNodeData(this.props.node.id, this.props.node.type, this.state.data))
  }

  onSubmit = (event: any) => {
    event.preventDefault()
    this.setState({ data: computeData(this.state.data) }, () => this.props.updateNodeData(this.props.node.id, this.props.node.type, this.state.data))
  }

  render () {
    const data = processGraph(this.state.data)
    const graphOptions = {
      tooltip: {
        trigger: 'axis',
        formatter: 'Month: {b0}<br /><center>{c0}</center>'
      },
      grid: [{
        left: 0,
        right: 0,
        top: 0,
        bottom: 17
      }],
      xAxis: {
        type: 'category',
        data: Array.from(Array(36).keys())
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          type: 'bar',
          data
        }
      ],
      animation: false
    }
    return (
      <div>
        <div>
          <div className="editorname">
            <BsCashCoin style={{marginRight: 5}}/>
            <BsCpu style={{marginRight: 12}}/>
            Money Process
          </div>
          <form onSubmit={this.onSubmit} style={{padding: '5px 10px'}}>
            <div className="mb-3">
              <label htmlFor="label" className="form-label inputlabel">Label</label>
              <input className="form-control" id="label" placeholder="" onBlur={this.onBlur} value={this.state.data.label} name="label" onChange={this.onChange}/>
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label inputlabel">Description</label>
              <textarea className="form-control" id="description" rows={3} onBlur={this.onBlur} value={this.state.data.description} name="description" onChange={this.onChange}></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="multiplier" className="form-label inputlabel">Multiplier rate / month</label>
              <input className="form-control" id="multiplier" placeholder='1' onBlur={this.onBlur} value={this.state.data.multiplier} name="multiplier" onChange={this.onChange}/>
            </div>
            <div className="mb-3 d-flex flex-row gap-2">
              <div>
                <label htmlFor="size" className="form-label inputlabel">Month From</label>
                <input className="form-control" id="from" placeholder="" onBlur={this.onBlur} value={this.state.data.from} name="from" onChange={this.onChange}/>
              </div>
              <div>
                <label htmlFor="size" className="form-label inputlabel">Month To</label>
                <input className="form-control" id="to" placeholder="" onBlur={this.onBlur} value={this.state.data.to} name="to" onChange={this.onChange}/>
              </div>
            </div>
            <input
              type="submit"
              style={{height: 0, width: 0, display: 'none'}}
            />
            <div className="mb-3">
              <div className="d-flex justify-content-between mb-2">
                <div className="inputlabel">Graph</div>
                <div><TbLayoutBottombar style={{cursor: 'pointer'}} onClick={()=>this.props.showGraph(this.props.node.id)}/></div>
              </div>
              <ReactECharts
                style={{height: '100px'}}
                option={graphOptions}
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}