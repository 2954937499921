import React, { Component } from 'react'
import { Link } from 'react-router-dom';

interface LandingImageProps {
}

type LandingImageStates = {

}

class LandingImage extends Component <LandingImageProps, LandingImageStates> {
  constructor (props: LandingImageProps) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
        <svg viewBox="0 0 543 488" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Artboard</title>
            <defs>
                <polygon id="path-1" points="0.15499407 0.208 105.911065 0.208 105.911065 183.865 0.15499407 183.865"></polygon>
                <polygon id="path-3" points="0.06 0.6918372 262.37228 0.6918372 262.37228 442.674 0.06 442.674"></polygon>
                <polygon id="path-5" points="0.904405992 0.208 106.661284 0.208 106.661284 183.865 0.904405992 183.865"></polygon>
                <polygon id="path-7" points="0.443684179 0.6918372 262.816 0.6918372 262.816 442.674 0.443684179 442.674"></polygon>
            </defs>
            <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="rainbow-heart-symbol">
                    <g id="Group-29" transform="translate(68.000000, 0.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <g id="Clip-27"></g>
                        <path d="M0.737,169.056 L12.484,105.576 L0.642,44.987 C-2.106,30.924 7.052,17.142 21.137,14.145 C35.222,11.148 48.939,20.063 51.836,34.095 L60.455,80.047 C62.745,92.253 63.392,104.73 62.377,117.149 L53.563,169.056 L16.602,183.865 L0.737,169.056" id="Fill-26" fill="#3F2A1B" mask="url(#mask-2)"></path>
                        <path d="M38.936,167.895 L51.603,99.435 L38.833,34.095 C35.869,18.928 45.746,4.065 60.935,0.833 C76.124,-2.399 90.918,7.216 94.042,22.349 L103.337,71.905 C105.806,85.068 106.503,98.524 105.41,111.916 L95.904,167.895 L56.044,183.865 L38.936,167.895" id="Fill-28" fill="#271D15" mask="url(#mask-2)"></path>
                    </g>
                    <path d="M143.49,169.056 L155.236,105.576 L143.395,44.987 C140.646,30.924 149.804,17.142 163.889,14.145 C177.973,11.148 191.692,20.063 194.588,34.095 L203.208,80.047 C205.497,92.253 206.143,104.73 205.129,117.149 L196.315,169.056 L159.354,183.865 L143.49,169.056" id="Fill-30" fill="#271D15"></path>
                    <path d="M207.474,312.466 C218.269,294.498 227.661,285.097 230.097,264.223 L241.206,169.056 L68.737,169.056 L54.284,233.86 L101.522,328.064 L123.187,490.674 L205.26,490.674 L207.474,312.466" id="Fill-31" fill="#3F2A1B"></path>
                    <g id="Group-35" transform="translate(0.000000, 48.000000)">
                        <mask id="mask-4" fill="white">
                            <use xlinkHref="#path-3"></use>
                        </mask>
                        <g id="Clip-33"></g>
                        <path d="M181.11,130.042 L209.18,64.777 L210.173,26.326 C210.609,11.964 222.564,0.511 236.924,0.694 C251.284,0.878 262.653,12.63 262.367,26.993 L261.478,50.416 C261.005,62.86 258.874,75.207 255.142,87.127 L241.206,121.056 L201.985,127.367 L181.11,130.042" id="Fill-32" fill="#3F2A1B" mask="url(#mask-4)"></path>
                        <path d="M63.606,442.674 L82.694,264.466 L66.975,256.558 C43.67,244.833 28.943,221.081 28.724,194.871 C28.615,181.805 26.646,168.846 22.875,156.381 C20.267,147.757 15.015,140.198 7.854,134.765 C0.356,129.075 -2.11,118.887 1.924,110.271 C5.959,101.655 15.409,96.931 24.644,98.912 C43.069,102.866 58.39,115.404 65.877,132.655 C71.568,145.77 84.464,154.23 98.825,154.273 C148.368,154.417 165.112,208.239 139.716,248.689 L137.306,442.674 L63.606,442.674" id="Fill-34" fill="#56381F" mask="url(#mask-4)"></path>
                    </g>
                    <polyline id="Fill-36" fill="#3F2A1B" points="163.04 306.405 93.635 312.205 162.803 325.386 163.04 306.405"></polyline>
                    <g id="Group-40" transform="translate(368.000000, 0.000000)">
                        <mask id="mask-6" fill="white">
                            <use xlinkHref="#path-5"></use>
                        </mask>
                        <g id="Clip-38"></g>
                        <path d="M106.079,169.056 L94.331,105.576 L106.174,44.987 C108.923,30.924 99.764,17.142 85.679,14.145 C71.594,11.148 57.875,20.063 54.979,34.095 L46.361,80.047 C44.07,92.253 43.423,104.73 44.439,117.149 L53.252,169.056 L90.215,183.865 L106.079,169.056" id="Fill-37" fill="#F4BE84" mask="url(#mask-6)"></path>
                        <path d="M67.879,167.895 L55.211,99.435 L67.981,34.095 C70.946,18.928 61.071,4.065 45.88,0.833 C30.692,-2.399 15.897,7.216 12.774,22.349 L3.479,71.905 C1.009,85.068 0.313,98.524 1.405,111.916 L10.911,167.895 L50.771,183.865 L67.879,167.895" id="Fill-39" fill="#ECA969" mask="url(#mask-6)"></path>
                    </g>
                    <path d="M399.325,169.056 L387.579,105.576 L399.42,44.987 C402.169,30.924 393.013,17.142 378.927,14.145 C364.842,11.148 351.123,20.063 348.228,34.095 L339.608,80.047 C337.318,92.253 336.673,104.73 337.687,117.149 L346.5,169.056 L383.462,183.865 L399.325,169.056" id="Fill-41" fill="#ECA969"></path>
                    <path d="M335.341,312.466 C324.548,294.498 315.153,285.097 312.718,264.223 L301.609,169.056 L474.079,169.056 L488.531,233.86 L441.295,328.064 L419.628,490.674 L337.555,490.674 L335.341,312.466" id="Fill-42" fill="#F4BE84"></path>
                    <g id="Group-46" transform="translate(280.000000, 48.000000)">
                        <mask id="mask-8" fill="white">
                            <use xlinkHref="#path-7"></use>
                        </mask>
                        <g id="Clip-44"></g>
                        <path d="M81.705,130.042 L53.637,64.777 L52.643,26.326 C52.206,11.964 40.252,0.511 25.892,0.694 C11.532,0.878 0.162,12.63 0.449,26.993 L1.338,50.416 C1.811,62.86 3.941,75.207 7.674,87.127 L21.609,121.056 L60.829,127.367 L81.705,130.042" id="Fill-43" fill="#F4BE84" mask="url(#mask-8)"></path>
                        <path d="M199.209,442.674 L180.123,264.466 L195.841,256.558 C219.145,244.833 233.872,221.081 234.092,194.871 C234.201,181.805 236.169,168.846 239.94,156.381 C242.55,147.757 247.803,140.198 254.963,134.765 C262.461,129.075 264.927,118.887 260.891,110.271 C256.855,101.655 247.407,96.931 238.171,98.912 C219.746,102.866 204.427,115.404 196.938,132.655 C191.246,145.77 178.35,154.23 163.989,154.273 C114.447,154.417 97.704,208.239 123.1,248.689 L125.509,442.674 L199.209,442.674" id="Fill-45" fill="#F7CA98" mask="url(#mask-8)"></path>
                    </g>
                    <path d="M284.841,119.145 C333.732,58.696 410.738,73.175 439.573,119.145 C446.779,130.63 450.983,144.08 450.983,158.818 C450.983,158.964 450.978,159.107 450.975,159.252 C450.915,173.703 447.873,186.922 442.397,199.36 C436.165,213.518 426.779,226.666 415.037,239.467 C402.659,252.962 387.666,266.072 370.998,279.575 C355.204,292.368 337.907,305.519 319.908,319.682 C304.102,332.119 287.756,345.336 271.407,359.79 C255.059,345.336 238.713,332.119 222.907,319.682 C204.908,305.519 187.611,292.368 171.817,279.575 C155.149,266.072 140.155,252.962 127.776,239.467 C116.035,226.666 106.651,213.518 100.417,199.36 C94.942,186.922 91.899,173.703 91.839,159.252 C91.838,159.107 91.831,158.964 91.831,158.818 C91.831,144.08 96.037,130.632 103.242,119.145 C132.08,73.17 209.083,58.697 257.975,119.145 C262.74,125.037 267.247,131.618 271.407,138.974 C275.567,131.618 280.075,125.037 284.841,119.145" id="Fill-47" fill="#F8C823"></path>
                    <polyline id="Fill-48" fill="#F4BE84" points="379.776 306.405 449.179 312.205 380.013 325.386 379.776 306.405"></polyline>
                    <path d="M257.975,119.145 L103.242,119.145 C132.08,73.17 209.083,58.697 257.975,119.145 Z M439.573,119.145 L284.841,119.145 C333.732,58.696 410.738,73.175 439.573,119.145 L439.573,119.145 Z" id="Fill-49" fill="#DB3987"></path>
                    <path d="M103.242,119.145 L257.975,119.145 C262.74,125.037 267.247,131.618 271.407,138.974 C275.567,131.618 280.075,125.037 284.841,119.145 L439.573,119.145 C446.779,130.63 450.983,144.08 450.983,158.818 C450.983,158.964 450.978,159.107 450.975,159.252 L91.839,159.252 C91.838,159.107 91.831,158.964 91.831,158.818 C91.831,144.08 96.037,130.632 103.242,119.145" id="Fill-50" fill="#EC9141"></path>
                    <path d="M91.839,159.252 L450.975,159.252 C450.915,173.703 447.873,186.922 442.397,199.36 L100.417,199.36 C94.942,186.922 91.899,173.703 91.839,159.252" id="Fill-51" fill="#F8C823"></path>
                    <path d="M100.417,199.36 L442.397,199.36 C436.165,213.518 426.779,226.666 415.037,239.467 L127.776,239.467 C116.035,226.666 106.651,213.518 100.417,199.36" id="Fill-52" fill="#63A143"></path>
                    <path d="M127.776,239.467 L415.037,239.467 C402.659,252.962 387.666,266.072 370.998,279.575 L171.817,279.575 C155.149,266.072 140.155,252.962 127.776,239.467" id="Fill-53" fill="#8E8EB9"></path>
                    <path d="M171.817,279.575 L370.998,279.575 C355.204,292.368 337.907,305.519 319.908,319.682 L222.907,319.682 C204.908,305.519 187.611,292.368 171.817,279.575" id="Fill-54" fill="#5F5E9A"></path>
                    <path d="M222.907,319.682 L319.908,319.682 C304.102,332.119 287.756,345.336 271.407,359.79 C255.059,345.336 238.713,332.119 222.907,319.682" id="Fill-55" fill="#682866"></path>
                    <path d="M151.696,92.037 C168.624,92.037 182.403,103.15 187.861,119.145 L111.618,119.145 C119.384,103.15 134.769,92.037 151.696,92.037" id="Fill-56" fill="#E15E97"></path>
                    <path d="M111.618,119.145 L187.861,119.145 C189.864,125.018 190.748,131.548 190.25,138.428 C189.71,145.917 187.594,152.99 184.295,159.252 L109.391,159.252 C106.996,152.99 105.902,145.917 106.443,138.428 C106.94,131.548 108.766,125.018 111.618,119.145" id="Fill-57" fill="#F0A35B"></path>
                    <path d="M109.391,159.252 L184.295,159.252 C176.309,174.415 161.375,184.82 144.997,184.82 C128.619,184.82 115.188,174.415 109.391,159.252" id="Fill-58" fill="#FDE51A"></path>
                    <path d="M439.573,119.145 L369.426,119.145 C360.256,104.159 346.628,92.258 330.377,85.224 C372.74,69.157 418.734,85.924 439.573,119.145" id="Fill-59" fill="#DA2077"></path>
                    <path d="M369.426,119.145 L439.573,119.145 C446.779,130.63 450.983,144.08 450.983,158.818 C450.983,158.964 450.978,159.107 450.975,159.252 L381.877,159.252 C381.696,155.887 381.315,152.507 380.726,149.126 C378.837,138.277 374.936,128.153 369.426,119.145" id="Fill-60" fill="#DF7D2D"></path>
                    <path d="M381.877,159.252 L450.975,159.252 C450.915,173.703 447.873,186.922 442.397,199.36 L374.274,199.36 C379.918,186.985 382.632,173.254 381.877,159.252" id="Fill-61" fill="#EEA32D"></path>
                    <path d="M271.407,239.467 L208.222,239.467 C227.761,229.083 251.449,228.867 271.407,239.467 Z M374.274,199.36 L442.397,199.36 C436.165,213.518 426.779,226.666 415.037,239.467 L336.525,239.467 C353.606,230.393 366.629,216.122 374.274,199.36 L374.274,199.36 Z" id="Fill-62" fill="#519442"></path>
                    <path d="M208.222,239.467 L271.407,239.467 C291.766,250.281 316.166,250.281 336.525,239.467 L415.037,239.467 C402.659,252.962 387.666,266.072 370.998,279.575 L171.817,279.575 C157.367,267.868 144.174,256.456 132.854,244.833 C152.135,260.145 179.354,260.396 198.914,245.441 C201.879,243.175 204.994,241.182 208.222,239.467" id="Fill-63" fill="#6767A0"></path>
                    <path d="M171.817,279.575 L370.998,279.575 C355.204,292.368 337.907,305.519 319.908,319.682 L222.907,319.682 C204.908,305.519 187.611,292.368 171.817,279.575" id="Fill-64" fill="#474A8C"></path>
                    <path d="M222.907,319.682 L319.908,319.682 C304.102,332.119 287.756,345.336 271.407,359.79 C255.059,345.336 238.713,332.119 222.907,319.682" id="Fill-65" fill="#562353"></path>
                </g>
            </g>
        </svg>
    );
  }
}

export default LandingImage
