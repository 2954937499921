import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { getCredentials } from './utils'

interface HeaderProps {
}

type HeaderStates = {

}

class Header extends Component <HeaderProps, HeaderStates> {
  constructor (props: HeaderProps) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { token } = getCredentials()
    return (
      <nav className="bg-white navbar navbar-expand-lg navbar-light py-lg-1 fw-light text-secondary border-bottom"> 
        <div className="container">
          <Link className="fw-bold navbar-brand text-primary" to={"/"} target="_pg_blank">Modelr</Link> 
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span></button>   
          <div className="collapse navbar-collapse " id="navbarNavDropdown"> 
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a className="nav-link px-lg-3 py-lg-3" href={"/#features"}>Features</a> 
              </li>
              <li className="nav-item">
                <a className="nav-link px-lg-3 py-lg-3" href={"/#pricing"}>Pricing</a> 
              </li>
              <li className="nav-item">
                <Link className="nav-link px-lg-3 py-lg-3" to={"/community"}>Community</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link px-lg-3 py-lg-3" to={"https://blog.modelr.xyz"}>Blog</Link>
              </li>
            </ul>
            <ul className="navbar-nav">                       
              { token === false ?
                <li className="nav-item">
                  <Link className="nav-link px-lg-3 py-lg-3" to={"/signin"}>Sign in</Link>
                </li>
              : null} 
            </ul>
            { token === false ?
              <Link className="btn btn-primary ms-lg-3" to={"/signup"}>Sign up</Link>
            :
              <Link className="btn btn-primary ms-lg-3" to={"/dashboard"}>Dashboard</Link>
            }
          </div>
        </div>             
      </nav>
    );
  }
}

export default Header
