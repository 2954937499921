export const config = {
  app: {
    apiUri: process.env.REACT_APP_API_URI || 'http://localhost:4000',
    baseUri: process.env.REACT_APP_BASE_URI || 'http://localhost:3000',
    demo: process.env.REACT_APP_DEMO || false,
  },
  meta: {
    append: ' - Modelr.xyz',
    title: 'Modelr.xyz',
    description: 'Modelr.xyz is an interactive startup modeling platform where you can model your startup from market to balance sheet.'
  }
}