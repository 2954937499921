import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import './index.scss';
import 'reactflow/dist/style.css'

import App from './App'
import Landing from './Landing'
import Login from './Login'
import Register from './Register'
import Dash from './Dash'
import Community from './Community'
import Payment from './Payment'
import PaymentError from './PaymentError'
import PaymentSuccess from './PaymentSuccess'

const router = createBrowserRouter([{
  path: "/p/:id/:share?",
  element: <App/>
},{
  path: "/",
  element: <Landing/>
},{
  path: "/community",
  element: <Community/>
},{
  path: "/dashboard",
  element: <Dash/>
},{
  path: "/signup",
  element: <Register/>
},{
  path: "/signin",
  element: <Login/>
},{
  path: "/payment",
  element: <Payment/>
},{
  path: "/payment-success",
  element: <PaymentSuccess/>
},{
  path: "/payment-error",
  element: <PaymentError/>
}]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  // </React.StrictMode>
);
