import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { config } from './config'
import { storeCredentials } from './utils'
import Header from './Header'
import { SEOHeaders } from "./SeoHeaders"

import './Register.scss'

interface RegisterProps {
    navigate: any
}

type RegisterStates = {
    logError: string
    logErrors: any
    logEmail: string
    logName: string
    logPassword: string
    logPassword2: string
}

class Register extends Component <RegisterProps, RegisterStates> {
  constructor (props: RegisterProps) {
    super(props)
    this.state = {
        logError: '',
        logErrors: {},
        logEmail: '',
        logName: '',
        logPassword: '',
        logPassword2: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as RegisterStates)
  }

  register = (event: any) => {
    event.preventDefault()
    fetch(
      config.app.apiUri + '/api/v1/me/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.state.logEmail,
          name: this.state.logName,
          password: this.state.logPassword,
          password2: this.state.logPassword2
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          storeCredentials(json.token)
          this.props.navigate('/payment')
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
        <div className="height-full-view bg-light">
          <SEOHeaders title={'Signup'} appendTitle/>
          <Header/>
            <div className="register d-flex justify-content-center align-items-center mt-5">
              <div className="card">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item text-center">
                          <Link className="nav-link btl" id="pills-home-tab" data-toggle="pill" to={"/signin"} role="tab" aria-controls="pills-home" aria-selected="true">Sign in</Link>
                      </li>
                      <li className="nav-item text-center">
                          <Link className="nav-link active btr" id="pills-profile-tab" data-toggle="pill" to={"/signup"} role="tab" aria-controls="pills-profile" aria-selected="false">Sign up</Link>
                      </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                          <form className="form px-4 pt-5" onSubmit={this.register}>
                              <input type="email" value={this.state.logEmail} name="logEmail" onChange={this.inputChange} className="form-control" placeholder="Email"/>
                              <input type="text" value={this.state.logName} name="logName" onChange={this.inputChange} className="form-control" placeholder="Showname"/>
                              <input type="password" value={this.state.logPassword} name="logPassword" onChange={this.inputChange} className="form-control" placeholder="Password"/>
                              <input type="password" value={this.state.logPassword2} name="logPassword2" onChange={this.inputChange} className="form-control" placeholder="Re-Password"/>
                              { this.state.logError !== ''
                                  ? <div className="alert alert-danger alerterror">
                                      {this.state.logError}
                                  </div>
                                  : null
                              }
                              <div className="d-flex justify-content-end">
                                <button className="btn btn-dark btn-block" style={{width: 150}}>Sign up</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    );
  }
}

export default function RegisterWithNavigate() {
    const navigate = useNavigate()
    return <Register navigate={navigate}/>
  }