import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { config } from './config'
import { getCredentials, deleteCredentials } from './utils'
import Header from './Header'
import { SEOHeaders } from "./SeoHeaders"
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import './Payment.scss'

interface PaymentProps {
  navigate: any
}

type PaymentStates = {
  stripe: any
  stripeSecret: any
  isLoading: boolean
  success: boolean
}

class Payment extends Component <PaymentProps, PaymentStates> {
  constructor (props: PaymentProps) {
    super(props)
    this.state = {
      stripe: undefined,
      stripeSecret: undefined,
      isLoading: false,
      success: false
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as PaymentStates)
  }

  componentDidMount () {
    this.loadPayment()
  }

  loadPayment = (e: any = undefined) => {
    if (e !== undefined) {
      e.preventDefault()
    }
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/payment', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          const stripe = await loadStripe(json.stripeKey)
          this.setState({
            stripe,
            stripeSecret: json.clientSecret
          })
        }
        if (json.status === 'nouser') {
          deleteCredentials()
          this.props.navigate('/signin')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleSubmit = async (e: any, elements: any, stripe: any) => {
    e.preventDefault();
    this.setState({isLoading: true})
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success`,
      },
    });
    console.log('master error', error)
    if (error.type === "card_error" || error.type === "validation_error") {
      // setMessage(error.message);
      console.log('error', error)
    } else {
      // setMessage("An unexpected error occured.");
      console.log('unexpected error')
    }
    this.setState({isLoading: false})
  }

  render() {
    return (
      <div className="height-full-view bg-light">
        <SEOHeaders title={'Payment'} appendTitle/>
        <Header/>
        <div className="payment d-flex justify-content-center align-items-center mt-5">
          <div className="card">
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                { this.state.stripe && this.state.stripeSecret ?
                  <EmbeddedCheckoutProvider
                    stripe={this.state.stripe}
                    options={{
                      clientSecret: this.state.stripeSecret,
                      onComplete: ()=>this.props.navigate('/dashboard')
                    }}
                  >
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function PaymentWithNavigation() {
  const navigate = useNavigate()
  return <Payment navigate={navigate}/>
}