import React, { Component } from 'react'
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  getRectOfNodes,
  getTransformForBounds,
  Node,
  Edge
} from 'reactflow';
import * as uuid from 'short-uuid'
import { Modal, Button, ButtonGroup, Dropdown, DropdownButton, Form } from 'react-bootstrap'
import { config } from './config'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteCredentials, getCredentials } from './utils'
import { toPng } from 'html-to-image'
import { SEOHeaders } from "./SeoHeaders"
import ReactECharts from 'echarts-for-react'
import { BsCloudCheck, BsCloudArrowUp, BsCloudSlash, BsPlus, BsBoxArrowRight, BsBucket, BsArrowRightShort, BsCashCoin, BsPeople, BsCpu } from "react-icons/bs"
import { MdClose, MdLink } from "react-icons/md"
import { FaGripLines } from "react-icons/fa"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Moment from 'react-moment'

import './App.scss'

import UsersSource from './nodes/UsersSource'
import UsersPool from './nodes/UsersPool'
import UsersProcessor from './nodes/UsersProcessor'
import UsersMoneyTransform from './nodes/UsersMoneyTransform'
import MoneyPool from './nodes/MoneyPool'
import MoneySource from './nodes/MoneySource'
import MoneyProcessor from './nodes/MoneyProcessor'

import UserSourceEdit, { computeData as computeDataUsersSource, emptyState as emptyStateUsersSource, processGraph as processGraphUsersSource } from './nodes/UsersSourceEdit'
import UsersPoolEdit, { computeData as computeDataUsersPool, emptyState as emptyStateUsersPool, processGraph as processGraphUsersPool } from './nodes/UsersPoolEdit'
import UsersProcessorEdit, { computeData as computeDataUsersProcessor, emptyState as emptyStateUsersProcessor, processGraph as processGraphUsersProcessor } from './nodes/UsersProcessorEdit'
import UsersMoneyTransformEdit, { computeData as computeDataUsersMoneyTransform, emptyState as emptyStateUsersMoneyTransform, processGraph as processGraphUsersMoneyTransform } from './nodes/UsersMoneyTransformEdit'
import MoneyPoolEdit, { computeData as computeDataMoneyPool, emptyState as emptyStateMoneyPool, processGraph as processGraphMoneyPool } from './nodes/MoneyPoolEdit'
import MoneySourceEdit, { computeData as computeDataMoneySource, emptyState as emptyStateMoneySource, processGraph as processGraphMoneySource } from './nodes/MoneySourceEdit'
import MoneyProcessorEdit, { computeData as computeDataMoneyProcessor, emptyState as emptyStateMoneyProcessor, processGraph as processGraphMoneyProcessor } from './nodes/MoneyProcessorEdit'

const nodeTypes: any = {
  'UsersSource': UsersSource,
  'UsersPool': UsersPool,
  'UsersProcessor': UsersProcessor,
  'UsersMoneyTransform': UsersMoneyTransform,
  'MoneyPool': MoneyPool,
  'MoneySource': MoneySource,
  'MoneyProcessor': MoneyProcessor
}

const computeTypes: any = {
  'UsersSource': computeDataUsersSource,
  'UsersPool': computeDataUsersPool,
  'UsersProcessor': computeDataUsersProcessor,
  'UsersMoneyTransform': computeDataUsersMoneyTransform,
  'MoneyPool': computeDataMoneyPool,
  'MoneySource': computeDataMoneySource,
  'MoneyProcessor': computeDataMoneyProcessor
}

const graphProcessTypes: any = {
  'UsersSource': processGraphUsersSource,
  'UsersPool': processGraphUsersPool,
  'UsersProcessor': processGraphUsersProcessor,
  'UsersMoneyTransform': processGraphUsersMoneyTransform,
  'MoneyPool': processGraphMoneyPool,
  'MoneySource': processGraphMoneySource,
  'MoneyProcessor': processGraphMoneyProcessor
}

const emtpyDataTypes: any = {
  'UsersSource': emptyStateUsersSource,
  'UsersPool': emptyStateUsersPool,
  'UsersProcessor': emptyStateUsersProcessor,
  'UsersMoneyTransform': emptyStateUsersMoneyTransform,
  'MoneyPool': emptyStateMoneyPool,
  'MoneySource': emptyStateMoneySource,
  'MoneyProcessor': emptyStateMoneyProcessor
}

const defaultEdgeOptions = { animated: true }

interface AppProps {
  navigate: any
  params: any
}

type AppStates = {
  isSaving: boolean
  name: string
  description: string
  nodes: Node[]
  edges: Edge[]
  sharings: any[]
  public: boolean
  planuser: any
  me: any
  graphs: string[]
  graphPos: number
  graphPosTracking: boolean
  showSharingModal: boolean
}

class App extends Component <AppProps, AppStates> {
  constructor (props: AppProps) {
    super(props)
    this.state = {
      isSaving: false,
      name: '',
      description: '',
      nodes: [],
      edges: [],
      sharings: [],
      public: false,
      planuser: undefined,
      me: null,
      graphs: [],
      graphPos: 200,
      graphPosTracking: false,
      showSharingModal: false
    }
  }

  onNodesChange = (changes: any) => {
    if (!this.modifyable() && changes[0].type !== 'select') return
    const nodes = applyNodeChanges(changes, this.state.nodes)
    this.setState({ nodes }, () => {
      if (changes[0].type === 'reset' || changes[0].type === 'remove' || (changes[0].type === 'select' && changes[0].selected === false)) {
        this.updatePlan()
      }
    })
  }

  onEdgesChange = (changes: any) => {
    if (!this.modifyable()) return
    const edges = applyEdgeChanges(changes, this.state.edges)
    this.setState({ edges }, () => {
      if (changes[0].type === 'reset' || changes[0].type === 'remove') {
        this.updatePlan()
      }
    })
  }

  onConnect = (changes: any) => {
    if (!this.modifyable()) return
    // check if proper node types
    const sourceNode = this.state.nodes.find((el)=>el.id === changes.source)
    const targetNode = this.state.nodes.find((el)=>el.id === changes.target)
    if (sourceNode === undefined || targetNode === undefined) return
    if (!(
      (sourceNode.type === 'UsersSource' && targetNode.type === 'UsersProcessor') ||
      (sourceNode.type === 'UsersProcessor' && targetNode.type === 'UsersPool') ||
      (sourceNode.type === 'UsersSource' && targetNode.type === 'UsersMoneyTransform') ||
      (sourceNode.type === 'UsersPool' && targetNode.type === 'UsersMoneyTransform') ||
      (sourceNode.type === 'UsersSource' && targetNode.type === 'MoneyPool') ||
      (sourceNode.type === 'UsersMoneyTransform' && targetNode.type === 'MoneyPool') ||
      (sourceNode.type === 'MoneyProcessor' && targetNode.type === 'MoneyPool') ||
      (sourceNode.type === 'UsersMoneyTransform' && targetNode.type === 'MoneyProcessor') ||
      (sourceNode.type === 'MoneySource' && targetNode.type === 'MoneyProcessor') ||
      (sourceNode.type === 'MoneySource' && targetNode.type === 'MoneyPool')
    )) return
    // check if already connected
    const isConnected = this.state.edges.find((el)=>el.target === changes.target && el.source === changes.source)
    if (isConnected) return
    // process
    const nodesToUpdate : any[] = []
    const nodes = this.state.nodes.map((node: any)=>{
      if (node.id === changes.target) {
        const tempIncoming = {...node.data.incoming}
        tempIncoming[sourceNode.id] = sourceNode.data.data
        const computedData = computeTypes[node.type]({...node.data, incoming: tempIncoming})
        node.data = computedData
        nodesToUpdate.push({id: node.id, data: computedData})
      }
      return node
    })
    for (const n of nodesToUpdate) {
      this.processNodesUpdates(nodes, n.id, n.data)
    }
    // update
    this.setState({ nodes, edges: addEdge(changes, this.state.edges) }, () => {
      this.updatePlan()
    })
  }

  addNode = (type: string) => {
    if (!this.modifyable()) return
    let nodes = this.state.nodes.map((node:any) => {
      node.selected = false
      return node
    })
    const id = uuid.generate()
    const data = {...emtpyDataTypes[type]}
    nodes = nodes.concat({ id, type, selected: true, position: { x: 0, y: 0 }, data }) 
    this.setState({ nodes })
  }

  updateNodeData = (id: string, type: string, data: any) => {
    if (!this.modifyable()) return
    const computedData = computeTypes[type](data)
    const nodes = this.state.nodes.map((node: any)=>{
      if (node.id === id) {
        node.data = { ...computedData }
      }
      return node
    })
    this.processNodesUpdates(nodes, id, computedData)
    this.setState({ nodes },()=>this.updatePlan())
  }

  onEdgesDelete = (edges: any) => {
    if (!this.modifyable()) return
    const edge = edges[0]
    const nodesToUpdate : any[] = []
    const nodes = this.state.nodes.map((node: any)=>{
      if (node.id === edge.target) {
        const tempIncoming = {...node.data.incoming}
        delete tempIncoming[edge.source]
        const computedData = computeTypes[node.type]({...node.data, incoming: tempIncoming})
        node.data = {...computedData}
        nodesToUpdate.push({id: node.id, data: {...computedData}})
      }
      return node
    })
    for (const node of nodesToUpdate) {
      this.processNodesUpdates(nodes, node.id, node.data)
    }
    this.setState({ nodes })
  }

  onNodesDelete = () => {
    // incoming handeled by onEdgesDelete
  }

  processNodesUpdates = (nodes: any[], fromId: string, fromData: any) => {
    const edges = this.state.edges.map((edge: any)=>{
      if (edge.source === fromId) {
        return edge.target
      }
      return false
    })
    const nodesToUpdate : any[] = []
    nodes.map((node: any)=>{
      if (edges.includes(node.id)) {
        const tempIncoming = node.data.incoming
        tempIncoming[fromId] = fromData.data
        const computedData = computeTypes[node.type]({...node.data, incoming: tempIncoming})
        node.data = {...computedData}
        nodesToUpdate.push({id: node.id, data: {...computedData}})
      }
      return node
    })
    for (const node of nodesToUpdate) {
      this.processNodesUpdates(nodes, node.id, node.data)
    }
  }

  componentDidMount () {
    this.loadPlan()
    this.startDraggableTracking()
  }

  mouseDownDraggable = (e: any)=>{
    if (e.target.id === 'graphholder') {
      this.setState({graphPosTracking: true})
    }
  }

  mouseUpDraggable = (e: any)=>{
    this.setState({graphPosTracking: false})
  }

  mouseMoveDraggable = (e: any)=>{
    if (this.state.graphPosTracking) {
      this.setState({graphPos: window.innerHeight - e.clientY + 10})
    }
  }

  startDraggableTracking () {
    document.addEventListener("mousedown", this.mouseDownDraggable)
    document.addEventListener("mouseup", this.mouseUpDraggable)
    document.addEventListener("mousemove", this.mouseMoveDraggable)
  }

  stopDraggableTracking () {
    document.removeEventListener("mousedown", this.mouseDownDraggable)
    document.removeEventListener("mouseup", this.mouseUpDraggable)
    document.removeEventListener("mousemove", this.mouseMoveDraggable)
  }

  componentWillUnmount() {
    this.stopDraggableTracking()
  }

  showGraph = (id: string) => {
    const graphs = this.state.graphs
    if (graphs.includes(id) === false) {
      graphs.push(id)
      this.setState({ graphs })
    }
  }

  removeGraph = (id: string) => {
    let graphs = this.state.graphs
    if (graphs.includes(id)) {
      graphs = graphs.filter((x: any) => x !== id)
      this.setState({ graphs })
    }
  }

  closeSharingModal = () => {
    this.setState({ showSharingModal: false })
  }

  openSharingModal = () => {
    this.setState({ showSharingModal: true })
  }

  loadPlan = () => {
    const { token } = getCredentials()
    const sharing = this.props.params.share ? '/'+this.props.params.share : ''
    fetch(
      config.app.apiUri + '/api/v1/plans/'+this.props.params.id + sharing, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({
            name: json.plan.name,
            description: json.plan.description,
            nodes: json.plan.data.nodes ? json.plan.data.nodes : [],
            edges: json.plan.data.edges ? json.plan.data.edges : [],
            planuser: json.plan.userIdusers,
            me: json.me,
            public: json.plan.public,
            sharings: json.plan.sharings
          })
        }
        if (json.status === 'nouser') {
          deleteCredentials()
          this.props.navigate('/signin')
        }
        if (json.status === 'nomodel') {
          this.props.navigate('/dashboard')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  updatePlan = async () => {
    if (!this.modifyable()) return
    this.setState({ isSaving: true })
    const { token } = getCredentials()
    const image = await this.createImage()
    fetch(
      config.app.apiUri + '/api/v1/plans/'+this.props.params.id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify({
          name: this.state.name,
          description: this.state.description,
          image,
          public: this.state.public,
          data: {
            nodes: this.state.nodes,
            edges: this.state.edges
          }
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          // console.log('success update')
        }
        if (json.status === 'nopay') {
          this.props.navigate('/payment')
        }
        if (json.status === 'nouser') {
          deleteCredentials()
          this.props.navigate('/signin')
        }
        this.stopSavingIcon()
      })
      .catch((error) => {
        console.log(error)
        this.stopSavingIcon()
      })
  }

  stopSavingIcon = () => {
    setTimeout(()=>this.setState({ isSaving: false }), 100)
  }

  createImage = async () => {
    const imageWidth = 500
    const imageHeight = 250
    const nodesBounds = getRectOfNodes(this.state.nodes)
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 5, 0.25, 0)
    const ref: any = document.querySelector('.react-flow__viewport')
    return toPng(ref, {
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth.toString(),
        height: imageHeight.toString(),
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    })
  }

  createShareLink = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/plans/'+this.props.params.id+'/share', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadPlan()
        }
        if (json.status === 'nopay') {
          this.props.navigate('/payment')
        }
        if (json.status === 'nouser') {
          deleteCredentials()
          this.props.navigate('/signin')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  removeShare = (share: any) => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/plans/'+share.planId+'/'+share.id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.loadPlan()
        }
        if (json.status === 'nopay') {
          this.props.navigate('/payment')
        }
        if (json.status === 'nouser') {
          deleteCredentials()
          this.props.navigate('/signin')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  modifyable = () => {
    return this.props.params.share || this.state.public || (this.state.me === this.state.planuser)
  }

  render() {
    const { token } = getCredentials()
    const selectedNode = this.state.nodes.find((n:any)=>n.selected === true)
    return (
      <div style={{height: '100vh'}}>
        <SEOHeaders title={this.state.name} appendTitle/>

        <nav className="bg-white navbar navbar-expand-lg navbar-light py-lg-1 fw-light text-secondary border-bottom"> 
          <div className="container-fluid">
            <Link className="fw-bold navbar-brand text-primary" to={"/"}>Modelr</Link> 
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown-3" aria-controls="navbarNavDropdown-3" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span></button>

            <div className="ps-4">{this.state.name}</div>

            {this.props.params.share === undefined ?
              <div className="ps-5 text-success">
                { this.state.isSaving ?
                  <div className="fw-lighter fs-6"><BsCloudArrowUp className="pe-1 fs-5"/> saving...</div>
                : 
                  <div className="fw-lighter fs-6"><BsCloudCheck className="pe-1 fs-5"/> changes saved</div>
                }
              </div>
            : 
              <div className="ps-5 text-warning">
                <div className="fw-lighter fs-6"><BsCloudSlash className="pe-1 fs-5"/>View only</div>
              </div>
            }

            <div className="collapse navbar-collapse"> 
              <ul className="ms-auto navbar-nav">
                {token ?
                  <>
                    <li className="nav-item">
                      <Link className="nav-link px-lg-3 py-lg-3" to={''} onClick={()=>this.openSharingModal()}>Sharing</Link> 
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link px-lg-3 py-lg-3" to={"/dashboard"}>Dashboard</Link>
                    </li>
                  </>
                : 
                  <>
                    <li className="nav-item">
                      <Link className="nav-link px-lg-3 py-lg-3 me-3" to={"/signin"}>Sign in</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="btn btn-primary my-lg-2" to={"/signin"}>Sign up</Link>
                    </li>
                  </>
                }
              </ul>
            </div>
            
          </div>             
        </nav>

        <div className="position-relative vw-100 bg-light">

          <div style={{ height: `calc(100vh - 65px - ${this.state.graphs.length > 0 ? this.state.graphPos : 0}px)`, width: 'calc(100vw - 300px)' }}>

            <ReactFlow
              nodes={this.state.nodes}
              edges={this.state.edges}
              onNodesChange={this.onNodesChange}
              onEdgesChange={this.onEdgesChange}
              onConnect={this.onConnect}
              nodeTypes={nodeTypes}
              onEdgesDelete={this.onEdgesDelete}
              onNodesDelete={this.onNodesDelete}
              defaultEdgeOptions={defaultEdgeOptions}
              nodeOrigin={[0.5, 0.5]}
              fitView={true}
              maxZoom={1}
              minZoom={0.1}
            >
              {/*<Controls />*/}
              {/*<MiniMap />*/}
              <Background gap={12} size={1} />
            </ReactFlow>

          </div>

          { this.state.graphs.length > 0 ?
            <div className="position-relative" style={{ height: this.state.graphPos + 'px', width: 'calc(100vw - 300px)' }}>
              <div className="position-absolute" id="graphholder" style={{top: -4, left: '50%', transform: 'translate(-50%, 0)', cursor: 'row-resize' }}><FaGripLines color={"#dee2e6"} size={14} style={{pointerEvents: 'none'}}/></div>
              <div className="d-flex flex-column border-top bg-white">
                {this.state.graphs.map((graph: any) => {
                  const node: any = this.state.nodes.find((item)=>item.id === graph)
                  if (node === undefined) return null
                  const data = graphProcessTypes[node.type](node.data)
                  const graphOptions = {
                    tooltip: {
                      trigger: 'axis',
                      formatter: 'Month: {b0}<br /><center>{c0}</center>'
                    },
                    grid: [{
                      left: 0,
                      right: 0,
                      top: 5,
                      bottom: 17
                    }],
                    xAxis: {
                      type: 'category',
                      data: Array.from(Array(36).keys())
                    },
                    yAxis: {
                      type: 'value'
                    },
                    series: [
                      {
                        type: 'bar',
                        data
                      }
                    ],
                    animation: false
                  }
                  const graphHeight = (this.state.graphPos - 1) / this.state.graphs.length
                  return <div key={graph} className="container-fluid" style={{ height: graphHeight + 'px' }}>
                    <div className="d-flex justify-content-between">
                      <div>{node.data.label ? node.data.label : "No label"}</div>
                      <MdClose size={20} style={{cursor: 'pointer', marginTop: 5}} onClick={()=>this.removeGraph(graph)}/>
                    </div>
                    <ReactECharts
                      style={{height: graphHeight - 30 + 'px'}}
                      option={graphOptions}
                    />
                  </div>
                })}
              </div>
            </div>
          : null}

          <div className="" style={{position: 'absolute', top: 10, left: 10}}>
            <ButtonGroup>
              <Button className="xxxnewbutton"><BsPlus size={22}/></Button>
              <DropdownButton as={ButtonGroup} title={<><BsBoxArrowRight style={{marginRight: 7}}/>Source</>} className="xxxdropdown">
                <Dropdown.Item onClick={() => this.addNode('UsersSource')}>
                  <BsPeople style={{marginRight: 5}}/>
                  <BsBoxArrowRight style={{marginRight: 12}}/>
                  Users Source
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.addNode('MoneySource')}>
                  <BsCashCoin style={{marginRight: 5}}/>
                  <BsBoxArrowRight style={{marginRight: 12}}/>
                  Money Source
                </Dropdown.Item>
              </DropdownButton>
              <DropdownButton as={ButtonGroup} title={<><BsCpu style={{marginRight: 7}}/>Process</>} className="xxxdropdown">
                <Dropdown.Item onClick={() => this.addNode('UsersProcessor')}>
                  <BsPeople style={{marginRight: 5}}/>
                  <BsCpu style={{marginRight: 12}}/>
                  Users Process
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.addNode('MoneyProcessor')}>
                  <BsCashCoin style={{marginRight: 5}}/>
                  <BsCpu style={{marginRight: 12}}/>
                  Money Process
                </Dropdown.Item>
              </DropdownButton>
              <DropdownButton as={ButtonGroup} title={<><BsArrowRightShort style={{marginRight: 7}}/>Transform</>} className="xxxdropdown">
                <Dropdown.Item onClick={() => this.addNode('UsersMoneyTransform')}>
                  <BsPeople style={{marginRight: 0}}/>
                  <BsArrowRightShort style={{marginRight: 2}}/>
                  <BsCashCoin style={{marginRight: 12}}/>
                  Users to Money
                </Dropdown.Item>
              </DropdownButton>
              <DropdownButton as={ButtonGroup} title={<><BsBucket style={{marginRight: 7}}/>Bucket</>} className="xxxdropdown">
                <Dropdown.Item onClick={() => this.addNode('UsersPool')}>
                  <BsPeople style={{marginRight: 5}}/>
                  <BsBucket style={{marginRight: 12}}/>
                  Users Bucket
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.addNode('MoneyPool')}>
                  <BsCashCoin style={{marginRight: 5}}/>
                  <BsBucket style={{marginRight: 12}}/>
                  Money Bucket
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </div>
          <div className="border-start overflow-scroll" style={{ position: 'absolute', right: '0', top: '0', width: '300px', backgroundColor: '#fff', overflow: 'hidden', height: `calc(100vh - 65px)` }}>
            {selectedNode ? 
              <div>
                {selectedNode.type === 'UsersSource' ? <UserSourceEdit modifyable={this.modifyable()} node={selectedNode} updateNodeData={this.updateNodeData} showGraph={this.showGraph}/> : null}
                {selectedNode.type === 'UsersPool' ? <UsersPoolEdit modifyable={this.modifyable()} node={selectedNode} updateNodeData={this.updateNodeData} showGraph={this.showGraph}/> : null}
                {selectedNode.type === 'UsersProcessor' ? <UsersProcessorEdit modifyable={this.modifyable()} node={selectedNode} updateNodeData={this.updateNodeData} showGraph={this.showGraph}/> : null}
                {selectedNode.type === 'UsersMoneyTransform' ? <UsersMoneyTransformEdit modifyable={this.modifyable()} node={selectedNode} updateNodeData={this.updateNodeData} showGraph={this.showGraph}/> : null}
                {selectedNode.type === 'MoneyPool' ? <MoneyPoolEdit modifyable={this.modifyable()} node={selectedNode} updateNodeData={this.updateNodeData} showGraph={this.showGraph}/> : null}
                {selectedNode.type === 'MoneySource' ? <MoneySourceEdit modifyable={this.modifyable()} node={selectedNode} updateNodeData={this.updateNodeData} showGraph={this.showGraph}/> : null}
                {selectedNode.type === 'MoneyProcessor' ? <MoneyProcessorEdit modifyable={this.modifyable()} node={selectedNode} updateNodeData={this.updateNodeData} showGraph={this.showGraph}/> : null}
              </div>
            :
              <div className="d-flex h-100 justify-content-center align-items-center fw-light">
                <div>Nothing selected</div>
              </div>
            }
          </div>

        </div>

        <Modal show={this.state.showSharingModal} onHide={this.closeSharingModal} animation={false} centered>
          <Modal.Header style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Modal.Title>Sharing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table mb-4">
              <thead>
                <tr>
                  <th scope="col">Links</th>
                  <th scope="col">Crated</th>
                  <th scope="col" style={{textAlign: 'right'}}>
                    <div style={{cursor: 'pointer'}} onClick={()=>this.createShareLink()}>+ New Link</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.sharings.map((share)=>{
                  return <tr key={share.id}>
                    <td>
                      <CopyToClipboard text={config.app.baseUri + '/p/' + share.planId + '/' + share.id}>
                        <div style={{cursor: 'pointer'}}>Copy <MdLink size={20}/></div>
                      </CopyToClipboard>
                    </td>
                    <td><Moment format="DD/MM/YYYY kk:mm:ss">{share.createdAt}</Moment></td>
                    <td style={{textAlign: 'right'}}><MdClose size={20} style={{cursor: 'pointer'}} onClick={()=>this.removeShare(share)}/></td>
                  </tr>
                })}
                {this.state.sharings.length === 0 ?
                  <tr>
                    <td colSpan={3} className="text-center p-4">
                      No sharing links
                    </td>
                  </tr>
                : null}
              </tbody>
            </table>
            <div className="d-flex p-2">
              <strong>General access</strong>
              <Form.Select onChange={()=>{}}>
                <option selected={this.state.public === false} onClick={() => this.setState({public: false},()=>this.updatePlan())}>
                  Restricted (Owner and Links)
                </option>
                <option selected={this.state.public === true} onClick={() => this.setState({public: true},()=>this.updatePlan())}>
                  Public (Shared to Community)
                </option>
              </Form.Select>
            </div>

          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

export default function AppWithNavigation() {
  const navigate = useNavigate()
  const params = useParams()
  return <App navigate={navigate} params={params}/>
}