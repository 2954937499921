import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { config } from './config'
import { getCredentials } from './utils'
import Header from './Header'
import { SEOHeaders } from "./SeoHeaders"
import { ElementsConsumer, Elements, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import './PaymentError.scss'

interface PaymentErrorProps {
  navigate: any
}

type PaymentErrorStates = {
  stripe: any
  stripeSecret: any
  isLoading: boolean
}

class PaymentError extends Component <PaymentErrorProps, PaymentErrorStates> {
  constructor (props: PaymentErrorProps) {
    super(props)
    this.state = {
      stripe: undefined,
      stripeSecret: undefined,
      isLoading: false
    }
  }

  componentDidMount () {
    // this.loadPayment()
  }

  loadPayment = () => {
    const { token } = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/payment', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          const stripe = await loadStripe(json.stripeKey)
          this.setState({
            stripe,
            stripeSecret: json.clientSecret
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleSubmit = async (e: any, elements: any, stripe: any) => {
    e.preventDefault();
    this.setState({isLoading: true})
    const { error } = await stripe.confirmPayment({
      elements,
      // clientSecret: this.state.stripeSecret,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
    });
    console.log('master error', error)
    if (error.type === "card_error" || error.type === "validation_error") {
      // setMessage(error.message);
      console.log('error', error)
    } else {
      // setMessage("An unexpected error occured.");
      console.log('unexpected error')
    }
    this.setState({isLoading: false})
  }

  render() {
    return (
      <div className="height-full-view bg-light">
        <SEOHeaders title={'Payment error'} appendTitle/>
        <Header/>
        <div className="paymenterror d-flex justify-content-center align-items-center mt-5">
          <div className="card">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item text-center">
                <Link className="nav-link active btl" id="pills-home-tab" data-toggle="pill" to={"/signin"} role="tab" aria-controls="pills-home" aria-selected="true">Sign in</Link>
              </li>
              <li className="nav-item text-center">
                <Link className="nav-link btr" id="pills-profile-tab" data-toggle="pill" to={"/signup"} role="tab" aria-controls="pills-profile" aria-selected="false">Sign up</Link>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                { this.state.stripe && this.state.stripeSecret ?
                  <Elements stripe={this.state.stripe} options={{ clientSecret: this.state.stripeSecret }}>
                    <ElementsConsumer>
                      {({stripe, elements}) => (
                        <form className="form px-4 pt-5" onSubmit={(e) => this.handleSubmit(e, elements, stripe)}>
                          <PaymentElement />
                          { this.state.isLoading === false ?
                            <button className="btn btn-dark btn-block mt-4" style={{width: '100px'}}>Pay</button>
                          : 
                            <button disabled className="btn btn-primary btn-block mt-4" style={{width: '100px'}}>
                              <div className="spinner-border" style={{height: 16, width: 16, marginTop: 4, borderWidth: 2}}>
                                <span className="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          }
                        </form>
                      )}
                    </ElementsConsumer>
                  </Elements>
                : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function PaymentErrorWithNavigation() {
  const navigate = useNavigate()
  return <PaymentError navigate={navigate}/>
}