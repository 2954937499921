import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { config } from './config'
import { getCredentials } from './utils'
import Header from './Header'
import { SEOHeaders } from "./SeoHeaders"
import { ElementsConsumer, Elements, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import './PaymentSuccess.scss'

interface PaymentSuccessProps {
  navigate: any
}

type PaymentSuccessStates = {
}

class PaymentSuccess extends Component <PaymentSuccessProps, PaymentSuccessStates> {
  constructor (props: PaymentSuccessProps) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <div className="height-full-view bg-light">
        <SEOHeaders title={'Payment success'} appendTitle/>
        <Header/>
        <div className="paymentsuccess d-flex justify-content-center align-items-center mt-5">
          <div className="card">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item text-center">
                <Link className="nav-link active btl" id="pills-home-tab" data-toggle="pill" to={"/signin"} role="tab" aria-controls="pills-home" aria-selected="true">Sign in</Link>
              </li>
              <li className="nav-item text-center">
                <Link className="nav-link btr" id="pills-profile-tab" data-toggle="pill" to={"/signup"} role="tab" aria-controls="pills-profile" aria-selected="false">Sign up</Link>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                Payment success
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function PaymentWithNavigation() {
  const navigate = useNavigate()
  return <PaymentSuccess navigate={navigate}/>
}