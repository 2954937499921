import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { config } from './config'
import { storeCredentials } from './utils'
import Header from './Header'
import { SEOHeaders } from "./SeoHeaders"

import './Login.scss'

interface LoginProps {
  navigate: any
}

type LoginStates = {
  logError: string
  logErrors: any
  logEmail: string
  logPassword: string
}

class Login extends Component <LoginProps, LoginStates> {
  constructor (props: LoginProps) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
      logEmail: '',
      logPassword: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as LoginStates)
  }

  login = (event: any) => {
    event.preventDefault()
    fetch(
      config.app.apiUri + '/api/v1/me/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.state.logEmail,
          password: this.state.logPassword
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          storeCredentials(json.token)
          this.props.navigate('/dashboard')
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <div className="height-full-view bg-light">
        <SEOHeaders title={'Signin'} appendTitle/>
        <Header/>
        <div className="login d-flex justify-content-center align-items-center mt-5">
          <div className="card">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item text-center">
                <Link className="nav-link active btl" id="pills-home-tab" data-toggle="pill" to={"/signin"} role="tab" aria-controls="pills-home" aria-selected="true">Sign in</Link>
              </li>
              <li className="nav-item text-center">
                <Link className="nav-link btr" id="pills-profile-tab" data-toggle="pill" to={"/signup"} role="tab" aria-controls="pills-profile" aria-selected="false">Sign up</Link>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <form className="form px-4 pt-5" onSubmit={this.login}>
                  <input type="email" value={this.state.logEmail} name="logEmail" onChange={this.inputChange} className="form-control" placeholder="Email"/>
                  <input type="password" value={this.state.logPassword} name="logPassword" onChange={this.inputChange} className="form-control" placeholder="Password"/>
                  { this.state.logError !== ''
                    ? <div className="alert alert-danger alerterror">
                      {this.state.logError}
                    </div>
                    : null
                  }
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-dark btn-block" style={{width: 150}}>Sign In</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function LoginWithNavigation() {
  const navigate = useNavigate()
  return <Login navigate={navigate}/>
}