import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { BsBoxArrowRight, BsBucket, BsArrowRightShort, BsCashCoin, BsPeople, BsCpu, BsCalendar3 } from "react-icons/bs";

export default memo(({ data, isConnectable, selected }: any) => {
  let from = ''
  let to = ''
  let amount = ''
  if (data && data.from !== undefined) {
    from = data.from
  }
  if (data && data.to !== undefined) {
    to = data.to
  }
  if (data && data.amount !== undefined) {
    amount = data.amount
  }
  return (
    <div className={selected ? "customSelectedNodeStyle" : "customNodeStyle"}>
      <div className="customNodeLabel">
        <BsCashCoin size={18} style={{marginRight: 5}}/>
        <BsBoxArrowRight size={18} style={{marginRight: 12}}/>
        {data.label ? data.label : "No label"}
      </div>
      <div className="customNodeBorder"></div>
      <div className="customNodeInfo">
        <div>{from}-{to}</div>
        <div>{amount}</div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        className="customConnectorNodeStyle"
        isConnectable={isConnectable}
      />
    </div>
  );
});