import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { BsBoxArrowRight, BsBucket, BsArrowRightShort, BsCashCoin, BsPeople, BsCpu, BsCalendar3 } from "react-icons/bs";

export default memo(({ data, isConnectable, selected }: any) => {
  let low = ''
  let high = ''
  if (data && data.data && data.data.length > 0) {
    low = data.data[0].money
    high = data.data[data.data.length-1].money
  }
  return (
    <div className={selected ? "customSelectedNodeStyle" : "customNodeStyle"}>
      <div className="customNodeLabel">
        <BsCashCoin size={18} style={{marginRight: 5}}/>
        <BsBucket size={18} style={{marginRight: 12}}/>
        {data.label ? data.label : "No label"}
      </div>
      <div className="customNodeBorder"></div>
      <div className="customNodeInfoCenter">
        {low} - {high}
      </div>
      <Handle
        type="target"
        position={Position.Top}
        className="customConnectorNodeStyle"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="customConnectorNodeStyle"
        isConnectable={isConnectable}
      />
    </div>
  );
});